import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";
import TagManager from "react-gtm-module";

import Loading from "containers/Loading";
import initStore from "store";
import App from "./App";
import { SearchBarHeader } from "./components";
import * as serviceWorker from "./serviceWorker";
import ChannelService from "ChannelService";
import { BrowserRouter as Router } from "react-router-dom";

import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import kr from "./locales/kr/index.json";
import en from "./locales/en/index.json";
import { getLocalStorage } from "utils/localStorage";
import { LANG } from "utils/localStorage/type";

const store = initStore();
const langMultiple = getLocalStorage(LANG, 0);

ChannelService.boot({
  pluginKey: "1d3199b9-855d-4458-a7fc-bb4c28f45383",
});

const tagManagerArgs = {
  gtmId: "GTM-PJ9W7NZ",
  dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

function getLoading() {
  setInterval(() => {}, 2000);
  return <Loading />;
}

i18next.init({
  interpolation: { escapeValue: false },
  lng: langMultiple === "en-US" ? "en" : "kr",
  resources: {
    kr: { translation: kr },
    en: { translation: en },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <Suspense fallback={getLoading()}>
        {/* <Loading /> */}
        <I18nextProvider i18n={i18next}>
          <Router>
            <App />
          </Router>
        </I18nextProvider>
        <SearchBarHeader />
      </Suspense>
    </StoreProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
