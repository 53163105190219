/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2022-01-24 11:53:41
 * @modify date 2022-01-24 11:53:41
 * @desc [description]
 */

import React, { useCallback, useRef, useEffect } from "react";
import Slider from "react-slick";
import { Button } from "antd";
import { useHistory } from "react-router";

/** Components */
import { Heading, SubTitle } from "components";

/** Utils */
import moneyConvert from "utils/moneyConvert";

/** Config */
import { UrlInternal } from "common/constants/endpoints";

/** Local Style */
import * as S from "./style";
import useWindowSize from "hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";

interface Props {
  data?: any;
  linkToPage?: any;
}

const ArtMaketSlider = ({ data = [], linkToPage }: Props) => {
  const history = useHistory();
  const sliderRef = useRef<any>(null);
  const { width }: any = useWindowSize();
  const { t } = useTranslation();

  const checkScreen = useCallback(() => {
    let overCheck = false;
    if (data?.length < 3) {
      overCheck = true;
    }

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: false,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1441,
          settings: {
            slidesToShow: overCheck ? 1 : 2,
            slidesToScroll: overCheck ? 1 : 2,
          },
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: overCheck ? 1 : 1,
            slidesToScroll: overCheck ? 1 : 1,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 541,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return settings;
  }, [data]);

  function next() {
    sliderRef.current.slickNext();
  }
  function previous() {
    sliderRef.current.slickPrev();
  }

  const renderTitle = (item: any) => {
    return (
      <S.CardMeta>
        <S.CardTitle>{item?.productName}</S.CardTitle>
        <S.CardPrice>
          {item?.originPrice !== item?.soldPrice && (
            <span className="old-price">
              {moneyConvertLang(item?.originPrice)}
            </span>
          )}
          {moneyConvertLang(item?.soldPrice)}
        </S.CardPrice>
      </S.CardMeta>
    );
  };

  function checkLinkDetail(productCategoryId: any) {
    if (productCategoryId === 1) return UrlInternal.ART_MARKET_PRODUCT_LIST; // 1: 아트상품 - hàng nghệ thuật artnguide hợp tác
    if (productCategoryId === 2) return UrlInternal.ART_MARKET_WON_LIST; // 2: 원화 - tranh gốc
    if (productCategoryId === 3)
      return UrlInternal.ART_MARKET_WOOD_BLOCK_PRINTS_LIST; // 3:판화 - tranh khắc
    if (productCategoryId === 4) return UrlInternal.ART_MARKET_PRINTS; // 4: Print - tranh in
  }

  useEffect(() => {
    const artList: any = document.querySelector("#artList");
    const artListCt: any = artList.querySelectorAll(".slick-track");
    artListCt[0].style.width = "31000px";

    artList.addEventListener("scroll", () => {
      if (width <= 1024 || width >= 1024) {
        artListCt[0].style.width = "31000px";
      }
      if (width <= 768) {
        artListCt[0].style.width = "19720px";
      }
    });
    artList.removeEventListener("scroll", () => {}, false);
  }, [width]);

  return (
    <S.Container>
      <Heading content={"아트마켓"} linkTo={linkToPage} />
      <S.TitleWp className="artmaket-head">
        <SubTitle content={"ART MARKET"} />
        <S.ArrowsTitle className="artmaket-slide-row-action">
          <S.ArrowText
            onClick={() => history.push(UrlInternal.ART_MARKET_PRODUCT_LIST)}
          >
            View all collections
          </S.ArrowText>
          <S.BtnGroup className="artmaket-slide-backnext-action">
            <Button
              type="link"
              onClick={previous}
              icon={<span className="ti ti-arrow-left" />}
            />
            <Button
              type="link"
              onClick={next}
              icon={<span className="ti ti-arrow-right" />}
            />
          </S.BtnGroup>
        </S.ArrowsTitle>
      </S.TitleWp>
      <S.WrappSlide id="artList">
        <Slider {...checkScreen()} ref={sliderRef}>
          {data?.map((item: any, index: number) => (
            <S.CardWp key={index} className="card-item">
              <S.CardContent>
                <img
                  src={item?.image ?? item?.thumbnail}
                  alt={"art"}
                  onClick={() =>
                    history.push(
                      `${checkLinkDetail(item?.productCategoryId)}/${item.id}`
                    )
                  }
                />
                {renderTitle(item)}
              </S.CardContent>
            </S.CardWp>
          ))}
        </Slider>
      </S.WrappSlide>
    </S.Container>
  );
};

export default ArtMaketSlider;
